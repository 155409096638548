import React from 'react'
import styled from 'styled-components'
import { useMenuItems } from '../../../../../hooks/useMenuItems'
import UniversalLink from '../../../../UniversalLink/UniversalLink'
import { Link as GatsbyLink } from 'gatsby'
import { isMobileOnly } from 'react-device-detect'

const MenuItems = ({ lineThickness = '0.2rem' }) => {
	const menuItems = useMenuItems()

	const filteredMenuItems = menuItems.filter(
		menuItem => menuItem.cssClasses.find(item => item !== 'sub-menu') || !menuItem.cssClasses.length
	)

	const hasLabel = menuItem => {
		return menuItem.cssClasses.find(item => item === 'label')
	}

	return (
		<ul>
			{filteredMenuItems.map(menuItem => (
				<MenuItemWrapper key={menuItem.id}>
					<li key={menuItem.id}>
						<UniversalLink
							to={menuItem.uri}
							activeClassName={'active'}
							isDropdown={!!menuItem.cssClasses.find(item => item === 'dropdown')}
						>
							<MenuLinkLabel lineThickness={lineThickness} background={menuItem.uri === '/offerte-aanvragen/'}>
								{hasLabel(menuItem) && <MenuLabel>Nieuw</MenuLabel>}
								{menuItem.label}
								{menuItem.childItems.length > 0 && menuItem.childItems && !isMobileOnly && (
									<DropdownHoverWrapper>
										<DropdownWrapper>
											{menuItem.childItems.map(item => {
												return (
													<DropdownItem key={item.url} to={item.url}>
														{item.label}
													</DropdownItem>
												)
											})}
										</DropdownWrapper>
									</DropdownHoverWrapper>
								)}
							</MenuLinkLabel>
						</UniversalLink>
					</li>
				</MenuItemWrapper>
			))}
		</ul>
	)
}

const MenuLinkLabel = styled.label`
	position: relative;
	font-family: ${props => props.theme.fonts.big};
	color: white;
	cursor: pointer;

	background-color: ${props => props.background && '#0685C5'};
	padding: ${props => props.background && '12px 20px'};
	border-radius: ${props => props.background && '5px'};

	padding-bottom: ${props => !props.background && '5px'};

	&:hover {
		background-color: ${props => props.background && '#109be0'};
	}

	::after {
		content: '';
		display: block;
		position: absolute;
		height: ${({ lineThickness }) => lineThickness};
		width: 100%;
		background-color: white;
		transform-origin: right top;
		transform: scale(0, 1);
		transition: color 0.1s, transform 0.2s ease-out;
	}

	:hover::after,
	:focus::after,
	&.active::after {
		content: '';
		display: ${props => (props.background ? 'none' : 'block')};
		position: absolute;
		height: ${({ lineThickness }) => lineThickness};
		width: 100%;
		background-color: white;
		transform-origin: left top;
		transform: scale(1, 1);
		transition: color 0.1s, transform 0.2s ease-out;
	}
`

const MenuLabel = styled.div`
	border-radius: 3px;
	width: fit-content;
	padding: 5px 10px;
	color: black;
	font-size: 11px;
	position: absolute;
	background-color: #b0bec5;
	bottom: 32px;
	right: 0px;

	&::after {
		content: '';
		position: absolute;
		left: 60%;
		top: 100%;
		width: 0;
		height: 0;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-top: 5px solid #b0bec5;
		clear: both;
	}
`

const DropdownItem = styled(GatsbyLink)`
	margin-right: 0 !important;
	color: #ffffff;

	&:hover {
		color: #b1bec5;
	}
`

const DropdownHoverWrapper = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	position: absolute;

	${MenuLinkLabel}:hover & {
		opacity: 100;
	}
`

const MenuItemWrapper = styled.div``

const DropdownWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	position: absolute;
	min-width: 140px;
	font-size: 13px;
	border-radius: 3px;
	color: white;
	padding: 15px 10px;
	font-family: Poppins;
	background-color: #656565;
	margin-top: 10px;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.1s, transform 0.2s ease-out;

	${MenuLinkLabel}:hover & {
		opacity: 100;
		visibility: visible;
	}

	${DropdownHoverWrapper}:hover & {
		opacity: 100;
	}
`

export default MenuItems
